.LoadingScreen {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #18171e;
  z-index: 1000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;

  &-icon {
    border-radius: 1em;
    width: 20%;
  }

  &-loadingSpinner {
    position: absolute;
    bottom: 80px;
  }

  &-delayedLoading {
    position: absolute;
    bottom: 40px;
    font-size: 0.7em;
    margin-top: 1em;
    font-weight: 600;
    color: #fefefe;
    text-align: center;
  }
}

@keyframes breathingAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
