.mainCard {
  width: 100%;
  height: 57%;
  /* height: calc(
    calc(100% + env(safe-area-inset-top)) * 0.605 - env(safe-area-inset-top)
  ); */
  bottom: 0;
  /* transform: translateY(62.5%); */
  /* top: 37.5%; */
  position: absolute;
  /* overflow: scroll !important; */
  /* padding-bottom: 1em; */
  display: flex;
  flex-direction: column;
}

.logoContainer {
  bottom: 57%;
  position: absolute;
  width: 100%;
  z-index: 1000000;
}

.updateSnackBar {
  margin-top: env(safe-area-inset-top);
}

@media only screen and (min-width: 900px) {
  .mainCard {
    height: 100%;
    width: 25.5em;
  }
}

.button {
  padding: 12px !important;
  border-radius: 12px !important;
  border: none;
  font-weight: bold;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-transform: none !important;
}

.indigo {
  background-color: rgb(140, 128, 252) !important;
  color: white !important;
}

.white {
  background-color: white;
  color: rgb(62, 57, 97);
}
