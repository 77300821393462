.page {
  width: 100%;
  padding: 0 12px 0 12px;
  box-sizing: border-box;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pageStructure {
  overflow-x: auto;
  text-align: center;
  border-radius: 0 0 12px 12px;
  padding: 15px 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 80px);
}

.pageStructure::-webkit-scrollbar {
  display: none;
}

.recentsList {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
}

.pageHeader {
  margin-left: 1px;
  font-size: 1.2rem;
  font-weight: 800;
}

.quote {
  font-size: 0.8rem;
  font-weight: lighter;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 1.2;
  word-wrap: nowrap;
  margin-bottom: 1.5rem;
}

.italics {
  font-style: italic;
}

.profile--image {
  border-radius: 50%;
  width: 140px;
}

.profession {
  font-size: 0.8rem;
}

.name {
  font-size: 0.8rem;
  font-weight: 800;
}

.card {
  width: 100% !important;
  border-radius: 16px !important;
  padding: 10px !important;
  box-sizing: border-box !important;
}

.Settings {
  &-helpText {
    font-size: 0.8rem;
    font-weight: 800;
    margin-bottom: 0;
    text-align: left;
    margin-bottom: 0.5rem;
  }
  &-settingInfo {
    font-size: 0.7rem;
    margin-bottom: 0;
    text-align: left;
    margin-bottom: 0.5rem;
  }
}

.versionPill {
  border-radius: 12px;
  padding: 8px;
  background-color: #e8e8e8;

  &-label {
    font-size: 0.7rem;
    font-weight: 800;
    margin-bottom: 0;
    text-align: left;
  }
}
