.NoServiceCard {
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  min-height: 150px;
  box-sizing: border-box;
  margin-bottom: 8px;
  position: relative;
  background: linear-gradient(45deg, rgb(154, 236, 220), rgb(172, 236, 204));
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-noWeekendService {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  &-restarts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: lighter;
    margin-bottom: 12px;
    font-size: 0.9rem;
  }
}
