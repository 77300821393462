@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  height: calc(100% + env(safe-area-inset-top));
}

body {
  font-family: 'Product Sans Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overscroll-behavior: contain;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

* {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.MuiCard-root {
  background-image: none !important;
}

.gm-style div {
  -webkit-transform: scale(1.002);
}

@font-face {
  font-family: 'Product Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Product Sans Regular'),
    url('./assets/fonts/ProductSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Product Sans Black';
  font-style: normal;
  font-weight: normal;
  src: local('Product Sans Black'),
    url('./assets/fonts/ProductSans-Black.woff') format('woff');
}
