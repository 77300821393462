.mapCard {
  width: 100%;
  position: relative;
  border-radius: 12px;
  height: 40vh;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);

  &-map {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-cover {
    background-color: black;
    opacity: 0.3;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;
    left: 0;
    border-radius: 12px;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 1000000;
    gap: 16px;

    &-busesAvailable {
      display: flex;
      flex-direction: row;
      font-weight: 600;
      align-items: center;
      line-clamp: 1;
      white-space: nowrap;
      font-size: 0.8rem;
      gap: 6px;
    }
  }

  &-routeNumberOverlay {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: rgba(70, 70, 70, 0.7);
    border-radius: 6px;
    color: white;
    padding: 6px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 0.8rem;
    z-index: 1000000;
  }

  .button {
    width: 40%;
  }
}
