.routeCard {
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  min-height: 150px;
  box-sizing: border-box;
  margin-bottom: 8px;
  position: relative;
  background: linear-gradient(
    45deg,
    rgba(0, 200, 154, 1),
    rgba(0, 255, 128, 1)
  );
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);

  &-overlay {
    background-color: black;
    opacity: 0.3;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 100000;
    left: 0;
    border-radius: 12px;
  }

  &-nextBusTimeLogo {
    max-width: fit-content;
    border-radius: 6px;
    background-color: rgba(0, 241, 185, 0.223);
    padding: 5px;
    padding-right: 7px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 0.8rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  &-noWeekendService {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  &-nextTime {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;

    &-startLocation {
      font-weight: lighter;
    }

    &-leavingTime {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}
