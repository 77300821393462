.HomePageView {
  position: relative;
  width: 100%;
  padding: 0 12px 0 12px;
  box-sizing: border-box;
  padding-top: calc(env(safe-area-inset-top) + 10px);
  margin-bottom: calc(env(safe-area-inset-bottom) + 100px);
  height: 100vh;
  display: flex;
  flex-direction: column;

  .pageStructure {
    text-align: center;
    border-radius: 0 0 12px 12px;
    padding: 15px 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    overflow: scroll;
    height: 100vh;
    padding-bottom: calc(env(safe-area-inset-bottom) + 180px);
  }

  .button {
    padding: 12px !important;
    border-radius: 12px !important;
    border: none;
    font-weight: bold;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    text-transform: none !important;
  }

  .indigo {
    background-color: rgb(140, 128, 252) !important;
    color: white !important;
  }

  .white {
    background-color: white;
    color: rgb(62, 57, 97);
  }
}
